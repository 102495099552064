<!-- 打算报告 -->
<template>
    <div class="report">
        <reportContent></reportContent>
    </div>
</template>
<script>
    import reportContent from './components/report_one.vue'
    import * as echarts from 'echarts';
    export default {
        components: {
            reportContent
        },
        data() {
            return {}
        },
        methods: {},
        mounted() {},
    };
</script>
<style lang='less' scoped>
    .report {
        cursor: default;
        max-width: 1920px;
        min-width: 1100px;
        margin: 0 auto;

        /deep/ .el-breadcrumb {
            color: #333;
            margin: 49px 0 32px;
        }
    }
</style>